import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import CommonHorizontalCard from "../../componets/CommonHorizontalCard";
import CommonSubHeading from "../../componets/CommonSubHeading";
import Carousel from "react-multi-carousel";
import { NavLink } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

const Bundel = ({ bundelArray }) => {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      // partialVisibilityGutter : 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      // partialVisibilityGutter: 40,
    },
    largeMobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  const colorArray = [
    "#EEEBFA",
    "#E9EFD6",
    "#F5EDD8",
    "#F4E3EF",
    "#DEEDFF"
  ];

  const buttonColor = {
    0: "#ffffff",
    1: "#ffffff",
    2: "#ffffff",
  };
  const countdownStyle = {
    background: "#FD7900",
    color: "#ffffff",
  };
  const setPadding = "4px 8px 4px 0px";
  return (
    <div className="Deal">
      <Container className="maxWidthContainerFluid excitingSection" fluid>
        {/* <CommonSubHeading
          heading={t("home.bundleDealsTitle")}
          title={true}
          viewAll={true}
        /> */}

        <div className="d-flex justify-content-between align-items-baseline">
          <CommonSubHeading heading={t("home.bundleDealsTitle")} title={true} />
          <NavLink to="bundle-deals">
            <div className="view_all">
              {t("global.viewAll")}
            </div>
          </NavLink>
        </div>

        <Carousel responsive={responsive} partialVisible={true}>
          {bundelArray.map((each, i) => {return(
            <div key={i} style={{ 
              padding: "0", 
              paddingRight: i === bundelArray.length - 1 ? "0" : "23px", 
              minWidth: "350px", 
              width: "100%",
              flex: "0 0 auto"
            }}>
            <CommonHorizontalCard
              key={i}
              data={each}
              color={colorArray[i % colorArray.length]}
              buttonColor={buttonColor[i.toString().slice(-1)]}
              setWidth={true}
              backwhite={true}
              setPadding={setPadding}
            />
            </div>
          )})}
        </Carousel>
      </Container>
    </div>
  );
};

export default Bundel;
