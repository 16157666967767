// import React from "react";
// import { Card, Row, Col } from "react-bootstrap";
// import Countdown, { zeroPad } from "react-countdown";
// import { HiOutlineClock } from "react-icons/hi";
// import { NavLink } from "react-router-dom";
// import { pushToDataLayer } from ".././utils/datalayerPush";

// const CommonHorizontalCard = ({
  // data,
  // color,
  // buttonColor,
  // whiteColor,
  // setWidth,
  // backwhite,
  // countdownStyle,
  // setPadding,
  // section_name,
  // deals,
// }) => {
//   const renderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//       return (
//         <span>
//           {zeroPad(days)}: {zeroPad(minutes)}: {zeroPad(seconds)}
//         </span>
//       );
//     } else {
//       // Render a countdown
//       return (
//         <span>
//           {zeroPad(days)}: {zeroPad(minutes)}: {zeroPad(seconds)}
//         </span>
//       );
//     }
//   };

//   // Function to track card click
//   const handleClick = () => {
//     pushToDataLayer("clicked_card_in_section", {
//       card_name: data.name,
//       section_name: section_name,
//       page_name: window.location.pathname,
//     }, false);
//   };
//   console.log("color", color)
//   return (
//     <NavLink to={`/details/${data.url}`} onClick={handleClick}>

//       <Card
//         className="border-0 horizontalCard"
        // style={{
        //   backgroundColor: `${color}`,

//           // padding: `${setWidth === true ? "1px" : "horizontalCard"}`,
//           width: `${setWidth === true ? "100%" : "92%"}`,
//           marginBottom: `${setWidth === false ? "8px" : "8px"}`,
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat"

//         }}
//       >
//         <Row>
//           <Col
//             xs={6} md={7}
//             style={{ borderRadius: "6px" }}
//             className={`${backwhite === true ? "order-2" : "order-1"}`}
//           >
//             {/* <div
//               className="cardImage"
//               style={{
//                 backgroundImage: `url(${data.image.replaceAll(" ", "%20")} )`,
//                 backgroundColor: "transparent", 
//                 backgroundSize: "contain", // Optional: makes sure image fits nicely
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 padding: "2px",
//               }}
//             ></div> */}
//             <div className="cardImage" style={{padding: "8px", overflow: "hidden", justifyContent: "center", alignItems: "center",boxSizing: "border-box",}}>
//               <img
//                 src={data.image.replaceAll(" ", "%20")}
//                 alt={data.name}
//                 style={{
//                   width: "98%",
//                   height: "auto",
                  // objectFit: "contain",
                  // backgroundColor: "transparent", // this only helps if image has alpha
                  // mixBlendMode: "multiply", // for blending image with background color
//                   borderRadius: "6px",
//                   // paddingTop: `${deals === true ? "33px" : "2px"}`,
//                   paddingBottom: `${deals === true ? "33px" : "2px"}`,
//                   boxSizing: "border-box",
//                 }}
//               />
//             </div>

//           </Col>
//           <Col
//             xs={6} md={5} /* style={{padding: '0px'}} */
//             className={`${backwhite === true ? "order-2" : "order-1"}`}
//           >
//             <div className="cardData">
//               {data.countdown !== undefined &&
//                 data.countdown !== "" &&
//                 data.countdown !== "Invalid date" && (
//                   <div
//                     className="orange_box d-flex gap-1"
//                     style={{
//                       background: `${countdownStyle.background}`,
//                       color: `${countdownStyle.color}`,
//                       /* padding: `${setPadding}`, */
//                     }}
//                   >
//                     <HiOutlineClock size={18} className="d-none d-lg-block" />
//                     <Countdown
//                       className="countdown"
//                       date={data.countdown}
//                       renderer={renderer}
//                     />
//                   </div>
//                 )}
//               <div className="descrip" style={{  fontSize: "14px"}}>{data.name}</div>
//               <div className="SellPrice" style={{ color: `${buttonColor}` }}>
//                 <span style={{ fontSize: '15px', fontWeight: '500', color: "#43494B" }}>{data.display_price.slice(0, 4)}</span> <span style={{ fontSize: '18px', fontWeight: '600', color: "#191B1C" }} >{data.display_price.slice(3, (data.display_price.length))}</span>
//               </div>
//               <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

//                 <div className="oldPrice" style={{ color: `#43494B`, whiteSpace: 'nowrap', fontSize: '15px', fontWeight: "500" }}>
//                   {data.old_price.replace("AED ", "")}
//                 </div>
//                 <div
//                   className="discountPercent pt-2"
//                   style={{ color: `#33B056`, whiteSpace: 'nowrap', fontSize: "14px", fontWeight: "600", backgroundColor: "#FFFFFF", borderRadius: "6px", padding: "4px 8px" }}
//                 >
//                   {data.percentage}% Off
//                 </div>
//               </div>

//             </div>
//           </Col>
//         </Row>
//       </Card>
//     </NavLink>
//   );
// };

// export default CommonHorizontalCard;
import React from "react";
import { Card, Row, Col, Badge } from "react-bootstrap";
import Countdown from "react-countdown";
import { HiOutlineClock } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { pushToDataLayer } from "../utils/datalayerPush";

const CommonHorizontalCard = ({ data,
  color,
  buttonColor,
  whiteColor,
  setWidth,
  backwhite,
  countdownStyle,
  setPadding,
  section_name,
  deals,}) => {

  const renderer = ({ hours, minutes, seconds }) => (
    <span>
      {`${hours.toString().padStart(2, "0")}h : ${minutes
        .toString()
        .padStart(2, "0")}m : ${seconds.toString().padStart(2, "0")}s`}
    </span>
  );

  const handleClick = () => {
    pushToDataLayer(
      "clicked_card_in_section",
      {
        card_name: data.name,
        section_name,
        page_name: window.location.pathname,
      },
      false
    );
  };

  return (
    <NavLink to={`/details/${data.url}`} onClick={handleClick} className="text-decoration-none">
      <Card className="horizontalCard"
      style={{
        backgroundColor: `${color}`}}
      >
        <Row className="g-0 align-items-center" >
          <Col xs={4} className="cardImage">
            <img src={data.image} alt={data.name} className="img-fluid" style={{  objectFit: "contain",
                  backgroundColor: "transparent", // this only helps if image has alpha
                  mixBlendMode: "multiply", // for blending image with background color
                  paddingLeft: "2px"  
                }} 
              />
          </Col>
          <Col xs={8}>
            <div className="cardData">
            {data.countdown && !isNaN(new Date(data.countdown).getTime())  &&
              (
                <div className="orange_box mb-2 d-inline-flex align-items-center">
            
                  <HiOutlineClock size={14} className="me-1" />
                  <Countdown date={data.countdown} renderer={renderer} />
           
                </div>
              )}
              <div className="descrip">{data.name}</div>
              <div className="SellPrice mt-1">
               <span style={{ fontSize: '15px', fontWeight: '500', color: "#43494B" }}>AED</span> <span>{data.display_price.replace("AED ", "")}</span>
              </div>
              <div className="d-flex align-items-center gap-2 mt-1">
                <div className="oldPrice"
                style={{ color: `#43494B`, whiteSpace: 'nowrap', fontSize: '15px', fontWeight: "500" }}
                >{data.old_price.replace("AED ", "")}</div>
                <div className="discountPercent"
                 style={{ color: `#33B056`, whiteSpace: 'nowrap', fontSize: "14px", fontWeight: "600", backgroundColor: "#FFFFFF", borderRadius: "6px", padding: "4px 8px" }}
                >{data.percentage}% OFF</div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </NavLink>
  );
};

export default CommonHorizontalCard;
