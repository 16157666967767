import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { GlobalCart } from "../App";
import jwt from "jwt-decode";
import call_apis from "../services/Apis";
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner';

const AddToCartAPI = ({ cartDetail, cartClick, setCartClick }) => {
  const { getCartData, userId, setcarterrMsg } = useContext(GlobalCart)
  const [isLoading, setIsLoading] = useState(false);
  const cookies = new Cookies();
  var userData = {};
  var dataLayer = {};

  const getUserdata = () => {
    if (cookies.get("oscad") === undefined) {
      userData = {
        product_id: cartDetail.id,
        ip_address: 0,
        user_id: userId,
        quantity: cartDetail.quantity,
      };
    } else {
      userData = {
        product_id: cartDetail.id,
        ip_address: cookies.get("oscad"),
        user_id: userId,
        quantity: cartDetail.quantity,
      };
    }
    addTocartFunc(userData)
  }

  const removeAnimation = () => {
    document.getElementById("shopCart").style.animation = "unset"
  }

  const addTocartFunc = (userData, e) => {
    // e.preventDefault()
    
    // console.log(userData)

    setIsLoading(true);
    //Adding datalayer
    call_apis.getProductDataId(userData.product_id)
    .then(dataLayerResponse => {
      dataLayer = dataLayerResponse.data.data.product[0];

      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "AED",
          value: dataLayer.display_price,
          items: [
            {
              item_id: '"' + dataLayer.sku + '"',
              item_name: '"' + dataLayer.name + '"',
              index: 0,
              item_brand: '"' + dataLayer.brand + '"',
              item_category: '"' + dataLayer.category_name + '"',
              item_category2: '"' + dataLayer.subcategory_name + '"',
              item_category3: '"' + dataLayer.sub_sub_category_name + '"',
              price: dataLayer.display_price,
              quantity: dataLayer.quantity
            }
          ]
        }
      });
    })
    .catch(error => {
      console.error('Error fetching product data:', error);
    });

    //End of adding datalayer

    axios
      .post("/api/AddToCart", userData)
      .then((response) => {
        var user_id = 0
        if (cookies.get("jwt_token") !== undefined) {
          var decoded = jwt(cookies.get("jwt_token"));
          user_id = decoded.user_id;
        }

        cookies.set('oscad', response.data.data.ip_address, {
          httpOnly: false,
          path: '/',
          maxAge: 60 * 60 * 24 * 5
        });

        if (response.data.data.msg === undefined) {
          toast.success("Item Added to Cart", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setIsLoading(false);
          setcarterrMsg("")
        } else {
          setIsLoading(false);
          toast.error(response.data.data.msg, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setcarterrMsg(response.data.data.msg)
        }
        //console.log("addTocartFunc",userId)
        getCartData(user_id);
        setCartClick(false);

      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getUserdata()
  }, [])

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99999,
          }}
        >
          <ThreeDots
            type="ThreeDots"
            color="#FFFFFF"
            height={50}
            width={50}
            style={{
              zIndex: 99999,
            }}
            visible={true}
          />
        </div>
      )}
    </div>
  );
};

export default AddToCartAPI;