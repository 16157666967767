import React, { useEffect, useState, useRef, useContext } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import call_apis from "../../services/Apis";
import InfiniteScroll from "react-infinite-scroll-component";
import MyAccount from "./MyAccount";
import Cookies from "universal-cookie";
import LoginComponent from "../../componets/LoginComponent";
import InnerProduct from "./InnerProduct";



const MyOrders = () => {
  const [orderData, setOrderData] = useState([]);
  const call_data = useRef(true);
  const [stop_Api, setstop_Api] = React.useState(true);
  const [page, setpage] = React.useState(1);


  const cookies = new Cookies();
  const refresh = (setItems) => { };

  const getOrderData = async () => {
    const response = await call_apis.getOrderData(page);
    console.log(response);
    if (response.data.data.length <= 0) {
      setstop_Api(false);
    }
    setpage(page + 1);
    setOrderData([...orderData, ...response.data.data]);
  };

  useEffect(() => {
    if (call_data) {
      getOrderData();
    }
    call_data.current = false;
  }, []);


  return (
    <>
      <Container className="mt-2 MyAccount maxWidthContainerFluid" fluid>
        <Row>
          <Col lg="3">
            <MyAccount />
          </Col>
          <Col lg="9">

            {cookies.get("jwt_token") !== undefined ? <>
              {orderData !== undefined && Object.keys(orderData).length > 0 ? (
                <div>
                  <Container
                    fluid
                    className="maxWidthContainerFluid categoryPage"
                  >
                    <div className="subCategoryTitle">My Orders</div>
                    <InfiniteScroll
                      dataLength={orderData.length}
                      next={() => {
                        getOrderData();
                      }}
                      hasMore={stop_Api}
                      loader={
                        <div className="">
                          <div className="loading-indicator"></div>
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                        </p>
                      }
                      refreshFunction={refresh}
                      style={{ overflow: "hidden" }}
                    >
                      <InnerProduct orderData={orderData} />
                    </InfiniteScroll>

                  </Container>
                </div>
              ) : (
                <div style={{ height: "50vh" }}>
                  <div className="loading-indicator"></div>
                </div>
              )}
            </> :
              <div id="popover-basic" className="">
                <LoginComponent />
              </div>
            }
          </Col>
        </Row>
      </Container>

    </>
  );
};

export default MyOrders;

