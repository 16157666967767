import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import React, { useState } from 'react'
import call_apis from '../../services/Apis';
import InnerProduct from './InnerProduct';

const TrackYourOrder = () => {
	const [orderId, setOrderId] = useState('');
	const [refId, setRefId] = useState('');
	const [trackOrderData, setTrackOrderData] = useState([]);
	const [pre,setPre] = useState("");
	const [mobile, setMobile] = useState("");
	const [error, setError] = useState("");
	const [loader, setLoader] = useState(false);


	const handleSubmitTrackOrder = async (e) => {
		e.preventDefault();
		setLoader(true);
		const contact = pre + mobile;
		const page = 1;
		if(orderId && contact){
			setRefId("");
			const resp = await call_apis.trackOrderData(orderId, contact);
			if(resp?.data?.data?.length>0){setTrackOrderData(resp.data.data); setError(""); setLoader(false)}else{setError("Ivalid ID or Contact Number"); setTrackOrderData(""); setLoader(false)}
		}else if(refId) {
			const response = await call_apis.getOrderData(page, refId);
		if (response?.data?.data?.length > 0) {
			 setTrackOrderData(response.data.data); setError("");setLoader(false) } else{setError("Invalid Ref ID"); setTrackOrderData(""); setLoader(false)} 
		}	
		else{setLoader(false); setError("Invalid request, please enter Ref ID or Order Id and Contact")}	

	}

	return (
		<Container className="maxWidthContainerFluid excitingSection paddingContainerFluid" fluid>
			{loader ? <>
				<div style={{ height: "50vh" }}>
                  <div className="loading-indicator"></div>
                </div>
			</>:<Row className='trackMyorderSection'>
				<Col md={9} style={{ margin: "auto" }}>
					<div className='track-order-section'>
						<div className='my-orders'>
							<div className='track-title'> Track Your Order </div>
							<Card className='mb-4 track-order-card'>
								<Card.Body>
									<Form onSubmit={handleSubmitTrackOrder}>
										<div className="row mb-4">
											<div className="form-group col-md-12">
												<input type="text"
													placeholder="Order Id*"
													name="order_id"
													className='trackinput'
													style={{outline: 'none', border: 'none' }}
													value={orderId}
													onChange={(e) => setOrderId(e.target.value)}
												/>
											</div>
										</div>
										<div className='row mb-4'>
											<div className='col-md-12'>
												<div className='form-row-first'>
													<span className='w-25'>
														<input type="text" placeholder="+971" id="code" name="code" disabled="" className='trackinput' />
													</span>
													<span className="w-25">
														<select name="pre" id="pre" className='trackinput'value={pre} onChange={(e) => setPre(e.target.value)}>
															<option value="50">50</option>
															<option value="52">52 </option>
															<option value="54">54 </option>
															<option value="55">55 </option>
															<option value="56">56 </option>
															<option value="58">58</option>
														</select>
													</span>
													<span className="w-50">
														<input type="text" placeholder="Phone" name="mobile" maxlength="7" pattern="[0-9]*" className='trackinput' value={mobile} 
														style={{outline: 'none', border: 'none' }}
														onChange={(e) =>setMobile(e.target.value)} />
													</span>
												</div>
											</div>

										</div>
										<center><b>OR</b></center>
										<div className='row mb-4'>
											<div className="col-md-12">
												<input type="text"
													placeholder="Reference Id*"
													name="Referencid"
													className='trackinput '
													style={{outline: 'none', border: 'none' }}
													value={refId}
													onChange={(e) => setRefId(e.target.value)}
												/>
											</div>
										</div>
										{error && (
											<div className="alert alert-danger">{error}</div>	
										)}
										<div className='row mb-4'>
											<div className="col-md-12">
												<input type="submit" id="sbt_button" value="Search Status"
													className=' button-link'
												/>
											</div>
										</div>
										<div className='row'>
											<InnerProduct orderData={trackOrderData} />
											<div className="col-md-12">
												<img src="/Assets/delivery-track.gif" alt="track" className='delivery-track'
													style={{ display: trackOrderData.length > 0 ? 'none' : 'block', width: "350px", margin: "auto" }} />
											</div>
										</div>

									</Form>
								</Card.Body>
							</Card>
						</div>
					</div>
				</Col>
			</Row>}
			
		</Container>
	)
}

export default TrackYourOrder
